<template>
  <div class="visit">
    <a-skeleton active :paragraph="false" :loading="!data">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link to="/">Moje wizyty</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item
          >Wizyta z dnia {{ data.parsedAppointmentAt.slice(0, 10) }} ({{
            data.doctor
          }})</a-breadcrumb-item
        >
      </a-breadcrumb>
    </a-skeleton>
    <a-skeleton
      style="margin-top:34px"
      active
      :loading="!data"
      :paragraph="false"
    >
      <h1>
        Wizyta z dnia {{ data.parsedAppointmentAt.slice(0, 10) }} ({{
          data.doctor
        }})
      </h1>
    </a-skeleton>

    <div class="visit__table">
      <div class="visit__table-header">
        <span>Dodane pliki</span>
        <div class="visit__table-header-action">
          <a-button @click="addDocumentVisible = true" type="link">
            <template v-slot:icon><PlusCircleOutlined /></template>Dodaj
            dokument
          </a-button>
        </div>
      </div>
      <a-table
        :columns="table.columns"
        :row-key="(record) => record.id"
        :data-source="table.data"
        :loading="table.loading"
        :pagination="table.pagination"
        @change="handleTableChange"
        :locale="{ emptyText: 'Brak danych' }"
      >
        <template v-slot:action="{ record }">
          <div class="visit__table-actions">
            <a-button @click="downloadDocument(record)" type="link">
              <template v-slot:icon><DownloadOutlined /></template>Pobierz
            </a-button>
          </div>
        </template>
      </a-table>
    </div>
    <a-alert
      v-if="data && data.processStatus === 'FINISHED'"
      class="visit__info"
      type="info"
      show-icon
    >
      <template v-slot:icon><InfoCircleOutlined /></template>
      <template v-slot:description>
        <a-row>
          <a-col :md="18">
            Chcesz zadać lekarzowi pytanie po odbytej wizycie? Możesz to zrobić
            na naszym portalu do 24h od zakończenia wizyty.</a-col
          >
          <a-col :md="6">
            <a-button @click="goToChat" type="link">
              Zadaj pytanie lekarzowi
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-alert>

    <add-documents
      v-model="addDocumentVisible"
      :visitId="id"
      @after-confirm-success="afterAddDocumentModalSuccess"
    />
  </div>
</template>

<script>
import AddDocuments from "@/components/AddDocuments";
import {
  PlusCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";

import { parseDate } from "@/utils";

export default {
  name: "Visit",
  props: ["id", "showAddDocuments"],
  components: {
    PlusCircleOutlined,
    AddDocuments,
    DownloadOutlined,
    InfoCircleOutlined,
  },
  mounted() {
    this.addDocumentVisible = !!this.showAddDocuments;

    this.$http.put(`patient-portal/api/notification/deactivate?type=DOCUMENT&appointmentId=${this.id}`);

    this.$http
      .get(`patient-portal/api/appointments/${this.id}`)
      .then((res) => {
        this.data = res.data;
        this.data.parsedAppointmentAt = parseDate(this.data.appointmentAt);
        this.data.isExpired =
          new Date() > new Date(this.data.conversationExpiresAt);
        this.getDocuments(this.table.pagination, this.table.sorter);
      })
      .catch(() => {
        this.$notification.open({
          message: "Wystąpił błąd podczas wczytywania szczegółów wizyty",
          class: "error",
        });
      });
  },
  data() {
    return {
      data: null,
      addDocumentVisible: false,
      table: {
        columns: [
          {
            title: "Plik",
            dataIndex: "fileName",
            sorter: true,
          },
          {
            title: "Data dodania",
            dataIndex: "uploadedAt",
            customRender: ({ record }) => {
              return parseDate(record.uploadedAt);
            },
            sorter: true,
            defaultSortOrder: "descend"
          },
          {
            title: "Wystawione przez",
            dataIndex: "uploadedBy",
            customRender: ({ record }) => {
              const authorTypeMap = {
                DOCTOR: "Doktor",
                PATIENT: "Pacjent",
              };
              return `${record.uploadedBy} (${
                authorTypeMap[record.uploaderRole]
              })`;
            },
            sorter: true,
          },
          {
            title: "Opcje",
            key: "action",
            slots: { customRender: "action" },
          },
        ],
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        loading: false,
      },
    };
  },
  methods: {
    goToChat() {
      this.$router.push({ name: "Chat", params: { id: this.id } });
    },
    afterAddDocumentModalSuccess() {
      this.getDocuments(this.table.pagination, {
        columnKey: "uploadedAt",
        field: "uploadedAt",
        order: "descend",
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.table.sorter = sorter;
      this.getDocuments(pagination, sorter);
    },
    downloadDocument(record) {
      this.$http({
        url: record.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: record.contentType })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", record.fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    getDocuments(pagination, sorter) {
      this.table.loading = true;
      let link = `patient-portal/api/documentation/${
        this.id
      }?page=${pagination.current - 1}&size=${pagination.pageSize}`;
      if (sorter) {
        link += `&sort=${sorter.field},${
          sorter.order === "ascend" ? "ASC" : "DESC"
        }`;
      }

      this.$http
        .get(link)
        .then((res) => {
          this.table.loading = false;
          this.table.data = res.data.content;
          this.table.pagination = {
            current: res.data.pageable.pageNumber + 1,
            total: res.data.totalElements,
            pageSize: res.data.size
          };
        })
        .catch(() => {
          this.$notification.open({
            message: "Wystąpił błąd podczas wczytywania dokumentów",
            class: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.visit__upload {
  border: none;
  width: 100%;
  ::v-global(.ant-upload) {
    width: 100%;
  }
  ::v-global(.ant-upload-list),
  ::v-global(.ant-upload .ant-btn) {
    width: 50%;
  }
}

.visit {
  padding: 24px 10px;
  max-width: $container-width;
  margin: 0 auto;

  h1 {
    margin-top: 32px;
    font-size: 20px;
    font-weight: bold;
  }
  .visit__info {
    margin-top: 18px;
    .ant-btn {
      float: right;
      margin-top: -6px;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }

  .visit__table {
    .visit__table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      > span {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .ant-table-wrapper {
      margin-top: 24px;
    }
  }
}
</style>
