<template>
  <a-modal
    title="Dodaj dokument"
    class="add-documents"
    v-model:visible="visible"
    :afterClose="afterClose"
  >
    <p>
      Załaduj dokumenty, które chcesz dodać do wizyty, aby udostępnić je
      lekarzowi oraz zachować w szczegółach wizyty.
    </p>

    <a-upload
      :showUploadList="{ showRemoveIcon: true, showPreviewIcon: false }"
      :remove="handleRemoveFile"
      :multiple="true"
      :beforeUpload="beforeUploadFile"
      :disabled="confirmLoading"
      class="visit__upload"
      :fileList="fileList"
    >
      <a-button> <UploadOutlined /> Załaduj </a-button>
    </a-upload>

    <template v-slot:footer>
      <a-button
        key="submit"
        type="primary"
        :loading="confirmLoading"
        :disabled="fileList.length === 0"
        @click="addDocuments"
      >
        Dodaj pliki
      </a-button>
      <a-button key="back" @click="handleCancel">
        Anuluj
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { UploadOutlined } from "@ant-design/icons-vue";

export default {
  name: "AddDocuments",
  components: {
    UploadOutlined,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visitId: {
      type: String,
      required: true,
    },
  },
  emits: ["after-confirm-success", "update:modelValue"],
  data() {
    return {
      confirmLoading: false,
      fileList: [],
      visible: false,
    };
  },
  watch: {
    modelValue(nv) {
      this.visible = nv;
    },
  },
  methods: {
    addDocuments() {
      this.confirmLoading = true;
      let promises = [];
      let notifications = [];

      this.fileList.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        promises.push(
          this.$http.post(
            `patient-portal/api/documentation/${this.visitId}`,
            formData
          )
        );
      });

      Promise.all(
        promises.map((p, index) => {
          p.catch((e) => {
            notifications.push({
              class: "error",
              file: this.fileList[index],
              message: this.$t(`ERROR.${e.response.status}1`),
            });
          });
          p.then(() => {
            notifications.push({
              class: "success",
              file: this.fileList[index],
              message: `Dokument ${this.fileList[index].name} został dodany pomyślnie`,
            });
          });
          return p;
        })
      ).finally(() => {
        this.confirmLoading = false;

        notifications.forEach((notify, index) => {
          if (notify.class === "success") {
            this.handleRemoveFile(notify.file);
          }
          setTimeout(() => {
            this.$notification.open(notify);
          }, index * 100);
        });

        if (this.fileList.length !== promises.length) {
          this.$emit("after-confirm-success");
        }

        if (this.fileList.length === 0) {
          this.visible = false;
        }
      });
    },
    handleRemoveFile(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUploadFile(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleCancel() {
      this.visible = false;
    },
    afterClose() {
      this.fileList = [];
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-documents {
}
</style>
